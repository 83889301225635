.row-services{
  margin-left: 1.3vw;
}

.cardBox {
  width: 200px;
  height: 200px;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px,
    rgba(0, 0, 0, 0.5) 0px 2px 25px 0px;
    
}
.tour{
  background: url(/assets/icons-services/tour.webp);
}
.congress{
  background: url(/assets/icons-services/congresso.webp);
}
.party{
  background: url(/assets/icons-services/party.webp);
}
.trip{
  background: url(/assets/icons-services/trip.webp);
}
.transfer{
  background: url(/assets/icons-services/transfer.webp);
}
.shows{
  background: url(/assets/icons-services/show.webp);
}
.airport{
  background: url(/assets/icons-services/airport.webp);
}
.family{
  background: url(/assets/icons-services/family.webp);
}
.card {
  position: absolute;
  width: 95%;
  height: 95%;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  padding: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
}

.card-span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.3rem;
  pointer-events: none;
  color: white;
  opacity: 0.8;
 
}

span {
  top: 75%;
}

.card .content h3 {
  font-size: 1.3rem;
  padding-bottom: 10px;
}
.card .content p {
  font-size: 1rem;
  line-height: 25px;
}
.card .content {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card:hover .content {
  transform: translateY(0);
  opacity: 1;
}

.cardBox::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 150%;
  background: #40E0D0;
background: -webkit-linear-gradient(to right, #089049, #c5c5c5, #089049);
background: linear-gradient(to right, #089049, #5c5c5c, #089049);
  animation: glowing01 20s linear infinite;
  transform-origin: center;
  animation: glowing 20s linear infinite;
}

@keyframes glowing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px){
  .card {
    background-size: cover;
  }

  .row-services{
    margin-left: -4.5vw;
  }
  .cardBox {
    width:80vw;
    margin: 3vh 0 0 7vw;
  }
} 
